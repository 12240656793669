import React from "react";
import "../App.css";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";

const header = ["Parameters", "Score"];
const columns = [
  { id: "score_count", label: "Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "call_closing", label: "Call Closing", minWidth: 100 },
  {
    id: "hold_dead_air_probing",
    label: "Hold Dead Air Probing",
    minWidth: 150,
  },
  {
    id: "customer_profiling_effective_probing",
    label: "Customer Profiling Effective Probing",
    minWidth: 150,
  },
  { id: "discount_offered", label: "Discount Offered", minWidth: 150 },
  {
    id: "upselling_pitch_for_pack_upgrade",
    label: "Upselling Pitch for Pack Upgrade",
    minWidth: 150,
  },
  {
    id: "sales_closure_or_sales_rebuttals",
    label: "Sales Closure or Sales Rebuttals",
    minWidth: 150,
  },
  { id: "product_presentation", label: "Product Presentation" },
  { id: "empathy", label: "Empathy", minWidth: 100 },
  {
    id: "speech_rate_and_clarity",
    label: "Speech Rate and Clarity",
    minWidth: 120,
  },
  { id: "active_listening", label: "Active Listening", minWidth: 150 },
  {
    id: "courteous_and_professionality",
    label: "Courteous and Professionality",
    minWidth: 120,
  },
  {
    id: "conversational_ability",
    label: "Conversational Ability",
    minWidth: 120,
  },
  { id: "tone", label: "Tone", minWidth: 150 },
];
const columns_poonawalla = [
  { id: "energetic_opening", label: "Energetic Opening", minWidth: 300 },
  {
    id: "acknowledgement_comprehension",
    label: "Acknowledgement Comprehensive",
    minWidth: 300,
  },
  {
    id: "closure_script_further_assistance",
    label: "Closure Script Further Assistance",
    minWidth: 300,
  },
  { id: "hold_script", label: "Hold Script", minWidth: 150 },
];
const columns_shobha = [
  { id: "score", label: "Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "call_closing", label: "Call Closing", minWidth: 100 },
  {
    id: "information_gathering",
    label: "Information Gathering",
    minWidth: 150,
  },
  {
    id: "providing_project_information",
    label: "Providing Project Information",
    minWidth: 150,
  },
  { id: "site_visit_pitch", label: "Site Visit Pitch", minWidth: 150 },
  {
    id: "rate_of_speech",
    label: "Rate of Speech",
    minWidth: 150,
  },
  {
    id: "volume",
    label: "Volume",
    minWidth: 150,
  },
  { id: "noisy_calls", label: "Noisy Calls" },
  {
    id: "interruptions",
    label: "Interruptions",
    minWidth: 120,
  },
  {
    id: "participation_ratio",
    label: "Participation Ratio",
    minWidth: 120,
  },
  {
    id: "dead_air",
    label: "Dead Air",
    minWidth: 120,
  },
];
const columns_adret = [
  { id: "score", label: "Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "call_closing", label: "Call Closing", minWidth: 100 },
  { id: "product_upsell", label: "Product Upsell", minWidth: 150 },
  { id: "usage_and_dosage", label: "Usage And Dosage", minWidth: 150 },
  { id: "payment_link_sent", label: "Payment Link Sent", minWidth: 100 },
  { id: "benifits", label: "Benifits", minWidth: 120 },
  { id: "customer_interest", label: "Customer Interest", minWidth: 120 },
];
const columns_wellness = [
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "closing", label: "Call Closing", minWidth: 100 },
  { id: "upselling", label: "Upselling", minWidth: 120 },
  { id: "customer_value", label: "Customer Value", minWidth: 120 },
  { id: "hold_process", label: "Payment Link Sent", minWidth: 100 },
  { id: "assurance", label: "Assurance", minWidth: 120 },
  {
    id: "informing_correct_TAT_for_resolution",
    label: "Informing Correct TAT for Resolution",
    minWidth: 160,
  },
  {
    id: "active_listening_and_interruption",
    label: "Active Listening and Interruption",
    minWidth: 150,
  },
  { id: "empathy", label: "Empathy", minWidth: 100 },
  {
    id: "verifying_the_customer",
    label: "Verifying the Customer",
    minWidth: 150,
  },
  { id: "further_assistance", label: "Further Assistance", minWidth: 120 },
];
const columns_clearTax = [
  { id: "score", label: "Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "call_closing", label: "Call Closing", minWidth: 100 },
  {
    id: "rate_of_speech",
    label: "Rate of Speech",
    minWidth: 150,
  },
  {
    id: "volume",
    label: "Volume",
    minWidth: 150,
  },
  { id: "noisy_calls", label: "Noisy Calls" },
  {
    id: "interruptions",
    label: "Interruptions",
    minWidth: 120,
  },
  {
    id: "participation_ratio",
    label: "Participation Ratio",
    minWidth: 120,
  },
  {
    id: "dead_air",
    label: "Dead Air",
    minWidth: 120,
  },
];
const columns_matrimony = [
  { id: "agent_names", label: "Agent Names", minWidth: 350 },
  { id: "call_opening", label: "Call Opening", minWidth: 350 },
  { id: "effective_probing", label: "Effective Probing", minWidth: 300 },
  { id: "interest_creation", label: "Interest Creation", minWidth: 300 },
  { id: "objection_handling", label: "Object Handling", minWidth: 300 },
  { id: "package_pitch", label: "Package Pitch", minWidth: 150 },
  {
    id: "convincing_and_need_creation",
    label: "Convincing and Need Creation",
    minWidth: 350,
  },
  { id: "product_usp", label: "Product USP", minWidth: 300 },
  {
    id: "price_pitch_and_discount",
    label: "Price Pitch and Discount",
    minWidth: 300,
  },
  {
    id: "utilize_opportunities",
    label: "Utilize Opportunities",
    minWidth: 300,
  },
  { id: "customer_engagement", label: "Customer Engagement", minWidth: 150 },
  { id: "active_listening", label: "Active Listening", minWidth: 300 },
  {
    id: "tone_and_voice_modulation",
    label: "Tone and Voice Modulation",
    minWidth: 300,
  },
  {
    id: "confidence_and_enthusiasm",
    label: "Confidence and Enthusiasm",
    minWidth: 150,
  },
  { id: "urgency_creation", label: "Urgency Creation", minWidth: 300 },
  {
    id: "summarization_and_call_closing",
    label: "Summarization and Call Closing",
    minWidth: 300,
  },
];


const columns_bisleri = [
  { id: "agent_names", label: "Agent Name", minWidth: 200 },
  { id: "score", label: "Score", minWidth: 100 },
  { id: "agent_lethargy_check", label: "Lethargy Check", minWidth: 100 },
  { id: "agent_mentioned_brand_at_closing", label: "Brand Mention Closing", minWidth: 180 },
  { id: "agent_acknowledge", label: "Acknowledge", minWidth: 120 },
  { id: "agent_interruption_avoidance", label: "Interruption Avoidance", minWidth: 150 },
  { id: "opening_assist", label: "Opening Assist", minWidth: 120 },
  { id: "agent_hold_update_refresh", label: "Hold Update Refresh", minWidth: 150 },
  { id: "agent_energetic_check", label: "Energetic Check", minWidth: 100 },
  { id: "agent_dead_air_avoidance", label: "Dead Air Avoidance", minWidth: 150 },
  { id: "language_switch_permission", label: "Language Switch", minWidth: 150 },
  { id: "agent_respond_on_time", label: "Respond On Time", minWidth: 120 },
  { id: "agent_upsell", label: "Upsell", minWidth: 100 },
  { id: "agent_calmness_patience", label: "Calmness & Patience", minWidth: 150 },
  { id: "agent_further_assistance", label: "Further Assistance", minWidth: 150 },
  { id: "agent_filler_avoidance", label: "Filler Avoidance", minWidth: 150 },
  { id: "brand_name_check", label: "Brand Name Check", minWidth: 120 },
  { id: "agent_professional", label: "Professional", minWidth: 100 },
  { id: "agent_retention_effort", label: "Retention Effort", minWidth: 120 },
  { id: "agent_rude_avoidence", label: "Rude Avoidance", minWidth: 120 },
  { id: "agent_thanked_customer", label: "Thanked Customer", minWidth: 120 },
  { id: "agent_avoid_customer_repeat", label: "Avoid Customer Repeat", minWidth: 150 },
  { id: "agent_positive_language", label: "Positive Language", minWidth: 150 },
  { id: "agent_speech_clarity", label: "Speech Clarity", minWidth: 120 },
  { id: "agent_empathy_check", label: "Empathy Check", minWidth: 120 },
  { id: "call_opening_intro", label: "Opening Intro", minWidth: 120 },
  { id: "agent_clear_articulation", label: "Clear Articulation", minWidth: 150 },
  { id: "agent_jargon_avoidance", label: "Jargon Avoidance", minWidth: 150 },
  { id: "agent_probing_check", label: "Probing Check", minWidth: 120 },
  { id: "agent_hold_thanking", label: "Hold Thanking", minWidth: 120 },
  { id: "agent_rate_of_speech_check", label: "Rate of Speech", minWidth: 150 },
  { id: "agent_slang_avoidance", label: "Slang Avoidance", minWidth: 150 },
  { id: "issue_resolved", label: "Issue Resolved", minWidth: 120 },
  { id: "agent_hold_return_timing", label: "Hold Return Timing", minWidth: 150 },
  { id: "agent_abusive_avoidence", label: "Abusive Avoidance", minWidth: 150 },
  { id: "order_verification", label: "Order Verification", minWidth: 150 },
  { id: "customer_name_confirm", label: "Name Confirmation", minWidth: 150 },
  { id: "agent_proactive", label: "Proactive", minWidth: 100 },
  { id: "repeat_caller", label: "Repeat Caller", minWidth: 100 },
  { id: "agent_polite", label: "Polite", minWidth: 100 },
  { id: "agent_call_disconnect_avoidence", label: "Call Disconnect Avoidance", minWidth: 180 },
  { id: "agent_speaking_clearly", label: "Speaking Clearly", minWidth: 150 },
  
 ];
const columns_piramal = [
  { id: "agent_names", label: "Agent Names", minWidth: 350 },
  { id: "score", label: "Score", minWidth: 100 },
  { id: "score_fatal", label: "Fatal Score", minWidth: 100 },
  { id: "sentiment", label: "Sentiment", minWidth: 100 },
  { id: "pace", label: "Pace", minWidth: 100 },
  { id: "pitch", label: "Pitch", minWidth: 120 },
  { id: "noise", label: "Noise", minWidth: 120 },
  { id: "active_listening", label: "Active Listening", minWidth: 100 },
  { id: "response", label: "Response", minWidth: 120 },
  { id: "etiquettes", label: "Etiquettes", minWidth: 120 },
  { id: "fluency", label: "Fluency", minWidth: 120 },
  { id: "clarity", label: "Clarity", minWidth: 120 },
  { id: "opening", label: "Opening", minWidth: 120 },
  {
    id: "closing",
    label: "Closing",
    minWidth: 120,
  },
  {
    id: "duration",
    label: "Duration",
    minWidth: 120,
  },
  { id: "satisfaction", label: "Satisfaction", minWidth: 100 },
  { id: "courtesy", label: "Courtesy", minWidth: 100 },
  {
    id: "information_gathering",
    label: "Information Gathering",
    minWidth: 150,
  },
  { id: "hold_time", label: "Hold Time", minWidth: 120 },
];
const columns_adret1 = [
  { id: "agent_names", label: "Agent Names", minWidth: 350 },
  { id: "score", label: "Score", minWidth: 100 },
  { id: "score_fatal", label: "Fatal Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "effective_probing", label: "Effective Probing", minWidth: 100 },
  { id: "product_benefit", label: "Product Benefit", minWidth: 120 },
  { id: "product_usage", label: "Product Usage", minWidth: 120 },

 
  {
    id: "pre_medical_history_check",
    label: "Pre Medical History Check",
    minWidth: 150,
  },
  {
    id: "order_confirmation_accuracy",
    label: "Order Confirmation Accuracy",
    minWidth: 120,
  },
  { id: "call_handling", label: "Call Handling", minWidth: 100 },
  {
    id: "prepaid_adherence",
    label: "Prepaid Adherence",
    minWidth: 120,
  },
    {
    id: "kapiva_app_pitching",
    label: "Kapiva App Pitching",
    minWidth: 100,
  },
  {
    id: "upselling",
    label: "Up Selling",
    minWidth: 100,
  },
  { id: "cross_selling", label: "Cross Selling", minWidth: 100 },
  { id: "ivr_transfer", label: "IVR Transfer", minWidth: 100 },
];
export const AgentDataDetails = (responseData) => {
  console.log("Get TAgent Data ", responseData);
  let bestParams = [];
  let needImprovement = [];
  let sortable = [];
  for (let arr in responseData.getAgentData[0]) {
    // console.log("inside responsedata", arr);
    sortable.push([arr, responseData.getAgentData[0][arr]]);
  }
  const sortedArr = sortable.sort(function (a, b) {
    // console.log("Sorted Array aaaa", a, "sortd array bbbbbbbb", b);
    return a[1] - b[1];
  });
  for (let i = 0; i < 3; i++) {
    needImprovement[i] = sortedArr[i];
  }
  for (let i = sortedArr.length - 1, j = 0; j < 3; i--) {
    // console.log("sorted array of i is", sortedArr[i]);
    if (sortedArr[i][0] !== "agent_names") {
      bestParams[j] = sortedArr[i];
      j++;
    }
  }

  function toCamelCase(str) {
    // Split the string into words.
    const words = str.split(/\W+/);
    // Capitalize the first letter of each word except the first.
    const camelCaseWords = words.map((word, index) => {
      return word[0].toUpperCase() + word.slice(1);
    });
    // Join the words back together into a single string.
    return camelCaseWords.join(" ");
  }

  function transformArray(inputArray) {
    inputArray.forEach((array) => {
      array[0] = toCamelCase(array[0].replace(/_/g, " "));
    });
    return inputArray;
  }

  return (
    <>
      <div className="col-md-12 row">
        <div className="col-md-7">
          <div className="insight">
            {responseData.getAgentData[0].agent_names}
          </div>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 115,
                height: 60,
                marginLeft: 2,
              },
            }}
          >
            <Paper className="aupage">
              Calls
              <div className="dapage"> {responseData.getAgentData[1]}</div>
            </Paper>
            <Paper className="aupage">
              Score
              <div className="dapage">{responseData.getAgentData[0].score}</div>
            </Paper>
            <Paper className="aupage">
              Avg Call Duration<br></br>
              <div className="dapage"> {responseData.getAgentData[2]}</div>
            </Paper>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 115,
                height: 60,
                marginLeft: 2,
              },
            }}
          ></Box>
          <div className="insight">Insights</div>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 245,
                height: 120,
                marginLeft: 2,
              },
            }}
          >
            <Paper className="aupage">
              <b>Best Parameters</b>
              <br></br>
              <br></br>
              <ul style={{ paddingLeft: "10px", textAlign: "center" }}>
                {bestParams &&
                  transformArray(bestParams).map((el, i) => (
                    <div key={i}>{el[0].replace(/_/g, " ")}</div>
                  ))}
              </ul>
            </Paper>
            <Paper className="aupage">
              <b>Needs Improvement</b>
              <br></br>
              <br></br>
              <ul style={{ paddingLeft: "10px", textAlign: "center" }}>
                {needImprovement &&
                  transformArray(needImprovement).map((el, i) => (
                    <div key={i}>{el[0].replace(/_/g, " ")}</div>
                  ))}
              </ul>
            </Paper>
          </Box>
        </div>
        {localStorage.getItem("user") === "redcliffe_lab" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Score
                </th>
              </tr>
              {columns.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "sobha_limited" ||
          localStorage.getItem("user") === "speech_demo_audit" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_shobha.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "bisleri" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Score
                </th>
              </tr>
              {columns_bisleri.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "poonawalla_fincorp" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Score
                </th>
              </tr>
              {columns_poonawalla.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "matrimony" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Score
                </th>
              </tr>
              {columns_matrimony.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "clearTax" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_clearTax.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "adret" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_adret.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "wellnessForever" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_wellness.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "piramal" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_piramal.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "adret1" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_adret1.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : null}
      </div>
    </>
  );
};
