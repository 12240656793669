import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../services/http.common";
import ErrorBoundary from "./../utils/error-boundary.component";
import msgService from "./../utils/toastService";
import ozonetelNewLogo from "../asserts/images/headerLogo_ozo.png";
import * as _ from "lodash";

const loginEndpoint = process.env.REACT_APP_BASE_URL + "/login";
class dashboardLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      passWord: "",
      errors: {
        userNameError: "",
        passWordError: "",
      },
    };
  }
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "userName":
        errors.userNameError = value.length < 3 ? "Invalid Username" : "";
        break;
      case "passWord":
        errors.passWordError =
          value.length < 2 ? "Please enter a valid password" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };
  handleSubmit = (event) => {
    let errors = this.state.errors;
    event.preventDefault();
    if (this.state.userName === "") {
      errors.userNameError = "Please enter Username";
      this.setState({
        userNameError: "Please enter Username",
      });
    }
    if (this.state.passWord === "") {
      errors.passWordError = "Please enter Password";
      this.setState({
        passWordError: "Please enter Password",
      });
    }
    if (!_.isEmpty(this.state.userName) && !_.isEmpty(this.state.passWord)) {
      axios({
        method: "post",
        url: loginEndpoint,
        data: { username: this.state.userName, password: this.state.passWord },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          if (!_.isEmpty(response.data)) {
            window.localStorage.setItem("authToken", response.data.authToken);
            window.localStorage.setItem("user", response.data.user);
            const temp = window;
            if (window.localStorage.getItem("user") === "allegiant") {
              temp.location = "/ConversationDashboard";
            } else if (
              window.localStorage.getItem("user") === "speech_demo_audit"
            ) {
              temp.location = "/SpeechDemoAudit";
            } else if (window.localStorage.getItem("user") === "adret1") {
              temp.location = "/Adret1DashBoard";
            } else if (window.localStorage.getItem("user") === "piramal") {
              temp.location = "/PiramalDashBoard";
            } else if (window.localStorage.getItem("user") === "runwal") {
              temp.location = "/RunwalDashBoard";
            } else if (window.localStorage.getItem("user") === "acko_inbound") {
              temp.location = "/AckoDashBoard";
            } else if (window.localStorage.getItem("user") === "tata_steel") {
              temp.location = "/TataSteelDashBoard";
            } else if (window.localStorage.getItem("user") === "hdbfs") {
              temp.location = "/HdbfsDashBoard";
            } else if (
              window.localStorage.getItem("user") === "hdbfs_process"
            ) {
              temp.location = "/HdbfsV2DashBoard";
            } else if (
              window.localStorage.getItem("user") === "hdbfs_recovery"
            ) {
              temp.location = "/HdbfsRecoveryDashBoard";
            } else if (window.localStorage.getItem("user") === "clearTax") {
              temp.location = "/ClearTaxDashBoard";
            } else if (window.localStorage.getItem("user") === "adret") {
              temp.location = "/AdretDemoDashBoard";
            } else if (window.localStorage.getItem("user") === "bisleri") {
              temp.location = "/BisleriDashboard";
            }  else if (
              window.localStorage.getItem("user") === "wellnessForever"
            ) {
              temp.location = "/WelnessDashBoard";
            } else if (window.localStorage.getItem("user") === "larsentoubro") {
              temp.location = "/larsentoubroDashBoard";
            } else if (
              window.localStorage.getItem("user") === "speech_demo_voc"
            ) {
              temp.location = "/SpeechDemoVOC";
            } else {
              temp.location = "/SpeechDashboard";
            }
          }
        })
        .catch(function (err) {
          msgService.notifyError("Invalid username (or) password", err);
        });
    }
  };
  render() {
    const { errors } = this.state;
    return (
      <ErrorBoundary>
        <ToastContainer />
        <div className="">
          <nav className="navbar">
            <img src={ozonetelNewLogo} alt="" className="img-prop" />
          </nav>
        </div>
        <div className="registration">
          <form className="form" onSubmit={this.handleSubmit}>
            <div className="form-data title">
              <label className="title-name">Conversational Intelligence</label>
            </div>
            <div className="form-data">
              <label>
                Username <span className="icon-color">*</span>
              </label>
            </div>
            <div className="form-data d-grid">
              <input
                className="input-field"
                name="userName"
                type="text"
                onChange={this.handleChange}
                placeholder="Username"
              />
              <div style={{ height: "10px" }}>
                {errors.userNameError.length > 0 && (
                  <span className="error">{errors.userNameError}</span>
                )}
              </div>
            </div>

            <div className="form-data">
              <label>
                Password <span className="icon-color">*</span>
              </label>
            </div>

            <div className="form-data d-grid">
              <input
                className="input-field"
                type="password"
                name="passWord"
                onChange={this.handleChange}
                placeholder="Password"
              />
              <div style={{ height: "10px" }}>
                {errors.passWordError.length > 0 && (
                  <span className="error">{errors.passWordError}</span>
                )}
              </div>
            </div>
            <div className="form-data">
              <input
                type="submit"
                className="submit-button hover-pointer"
                value="Login"
              />
            </div>
          </form>
        </div>
      </ErrorBoundary>
    );
  }
}

export default withRouter(dashboardLogin);
